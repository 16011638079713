export default [
	{
		code: 'en',
		file: 'en.js',
	},
	{
		code: 'nl',
		file: 'nl.js',
	},
	{
		code: 'de',
		file: 'de.js',
	},
	{
		code: 'fr',
		file: 'fr.js',
	},
	{
		code: 'es',
		file: 'es.js',
	},
];
